.component_logo {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}
img {
  max-width: 20%;
  height: auto;
  max-height: 130px;
  object-fit: cover;
}